import logo from './logo.svg';
import './App.css';
import { BrowserRouter, Routes, Route } from 'react-router-dom';

function App() {

    const MainPage = () => {
        return (
            <div style={{ margin:15 }}>
                <div style={{fontSize:19, alignSelf:'center' }}>Privacy Policy</div>
                <div style={{fontSize:15, }}>Last updated: September 10th, 2021 <br />
                    <br />
                    This privacy policy governs your use of the software applications (“Applications”) for mobile devices and web that were created by AllAmericanHighSchoolSports INC. <br />
                    <br />
                    This Privacy Policy also describes the guidelines AllAmericanHighSchoolSports INC. uses to protect the information we collect about you during your visit to our Web site<br />
                    <br />
                    </div>
                <div style={{fontSize:16, }}>What information do Applications obtain and how is it used?<br /><br /></div>
                <div style={{fontSize:16, }}>Automatically Collected Information</div>
                <div style={{fontSize:15, }}>
                    Applications may collect certain information automatically, including, but not limited to, the type of mobile 
                    device you use, your mobile devices unique device ID, the IP address of your mobile device, your mobile operating 
                    system, the type of mobile Internet browsers you use, and information about the way you use the Application.
                    <br /><br />
                </div>
                <div style={{fontSize:16, }}>Personally Identifiable Information</div>
                <div style={{fontSize:15, }}>
                    We may collect personally identifiable information shared with Applications or while you are visiting the 
                    site, or during a registration via our Web site. We may also collect personally identifiable information 
                    from third parties used for authentication, such as when a user logs in to a Application using a Facebook 
                    account or Play Game Services account. Personally identifiable information collected may include information
                    like name, email address, location, gender, interests, device, app usage, user ID, and contact information. 
                    This personally identifiable information is used to store and synchronize user information across different 
                    devices. Personally identifiable information is never shared with any third parties unless required by law.
                    <br /><br />
                </div>
                <div style={{fontSize:16, }}>Do third parties see and/or have access to information obtained by the Application?</div>
                <div style={{fontSize:15, }}>
                    Yes. We will share your information with third parties only in the ways that are described in this privacy statement. 
                    <br /><br />
                </div>
                <div style={{fontSize:15, paddingLeft:20}}>
                    - as required by law, such as to comply with a subpoena, or similar legal process;
                    <br /><br />
                </div>
                <div style={{fontSize:15, paddingLeft:20}}>
                    - when we believe in good faith that disclosure is necessary to protect our rights,
                    protect your safety or the safety of others, investigate fraud, or respond to a government request;
                    <br /><br />
                </div>
                <div style={{fontSize:15, paddingLeft:20}}>
                    - with our trusted services providers who work on our behalf, do not have an independent use of the 
                    information we disclose to them, and have agreed to adhere to the rules set forth in this privacy statement.
                    <br /><br />
                </div>
                <div style={{fontSize:16, }}>Automatic Data Collection and Advertising<br /></div>
                <div style={{fontSize:15,}}>
                We may work with analytics companies to help us understand how Applications are being used, such as the frequency
                and duration of usage. We work with advertisers and third party advertising networks, who need to know how 
                you interact with advertising provided in Applications which helps us keep the cost of Applications low. 
                Advertisers and advertising networks use some of the information collected by Applications, including, 
                but not limited to, the unique identification ID of your mobile. These third parties may also obtain anonymous
                information about other applications you’ve downloaded to your mobile device, the mobile websites you visit,
                your location information (e.g., your zip code), and other location information in order to help analyze and 
                serve anonymous targeted advertising on Applications and elsewhere. We may also share information you have 
                provided in order to enable our partners to append other available information about you for analysis or 
                advertising related use.
                <br /><br />
                In addition to other information described in this policy, we may collect and share precise location 
                information, through methods such as mobile “SDKs”. This location information may be used by itself, 
                aggregated, or combined with mobile identifiers (such as IDFAs and Android IDs), and shared with third 
                parties, for purposes related to advertising, attribution (e.g., measuring ad performance) analytics and 
                research. You can opt-out from any interest-based advertising through your device settings. When you have 
                opted out using this setting on a device, advertisers will not use in-app information collected from that 
                device to infer your interests or serve ads to that device that are targeted based on your inferred interests.
                <br /><br />
                By installing and using our applications you also agree to the privacy policies of our 3rd party data and 
                advertising policies listed below.
                <br /><br />
                If you’d like to opt-out from third party use of this type of information to help serve targeted advertising,
                please visit the section entitled “Opt-out” below.
                <br /><br />
                </div>
                <div style={{fontSize:16, }}>What are my opt-out rights?<br /></div>
                <div style={{fontSize:15,}}>
                There are multiple opt-out options for users of Applications:
                <br /><br />
                Opt-out of all information collection by uninstalling the Application: You can stop all
                collection of information by the Application easily by uninstalling the Application. You
                may use the standard uninstall processes as may be available as part of your mobile device 
                or via the mobile application marketplace or network. Please consult your settings features 
                on your mobile device to opt-out of third- party targeted advertising. Additionally, there 
                are several applications available at App Stores that will manage advertising preferences for
                you. Information on how to turn off the cookies and tracking settings for various devices and
                    browsers can be found below. You are encouraged to view the privacy policies and opt-out options 
                    of these third-parties.
                    <br /><br />
                    Google<br />
                    Privacy Policy http://www.google.com/policies/privacy/ads/<br />
                    Opt-Out http://www.google.com/policies/privacy/ads/
                    <br /><br />
                
                </div>
                <div style={{fontSize:16, }}>Data Retention Policy, Managing Your Information<br /></div>
                <div style={{fontSize:15,}}>
                    We will retain Automatically Collected information for up to 24 months and thereafter may store it in aggregate.
                    <br /><br />
                </div>
                <div style={{fontSize:16, }}>How do we protect your information?<br /></div>
                <div style={{fontSize:15,}}>
                    We implement a variety of security measures to maintain the safety of your personal information when you enter, submit,
                    or access your personal information. We offer the use of a secure server.
                    <br /><br />
                </div>
                <div style={{fontSize:15, }}>Changes to our Privacy Policy<br /></div>
                <div style={{fontSize:15,}}>
                If we decide to change our privacy policy, we will post those changes on this page.  
                <br /><br />
                </div>
                <div style={{fontSize:16, }}>Your Consent<br /></div>
                <div style={{fontSize:15,}}>
                By using the Application, you are consenting to our processing of your information as set forth in
                this Privacy Policy now and as amended by us. “Processing,” means using cookies on a computer/hand 
                held device or using or touching information in any way, including, but not limited to, collecting, 
                transferring, storing, deleting, using, combining and disclosing information.
                <br /><br />
                Irrespective of which country you live in, you authorize us to use your information in, and as a 
                result to transfer it to and store it in, the United States and any other country where we, our 
                service providers, and our partners operate. Privacy and data protection laws in some of these 
                countries may vary from the laws in the country where you live.
                <br /><br />
                </div>
                <div style={{fontSize:16, }}>Contacting Us<br /></div>
                <div style={{fontSize:15,}}>
                If there are any questions regarding this privacy policy you may contact us using the information below.
                <br /><br />
                support@iclockingames.com
                <br /><br />
                </div>
        </div>
        )
    }

    const Daccount = () => {
        return (
            <div>
                <h1>Request to Delete Account</h1>
                <button >
                    <a href="mailto:support@iclockingames.com?subject=Request to deleted my Account&body=Name:        Password:  ">
                    Delete Account
                    </a>
                </button>
            </div>
        )
    }

  return (
    <BrowserRouter>
        <Routes>
            <Route path="/" element={<MainPage />} />
            <Route path="/deleteaccount" element={<Daccount />} />
        </Routes>
    </BrowserRouter>
  )
   
}

export default App;
